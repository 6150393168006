<template>
  <eden-confirm-dialog
    :title="'Delete Rider'"
    :button-text="'Delete'"
    :button-type="'danger'"
    :button-status.sync="deleting"
    :show.sync="setShow"
    @confirm="deleteRider"
  >
    <p>
      This action cannot be undone. Are you sure you want to delete,
      <span class="text-grey-primary text-bold">{{ rider.name }}</span
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import riders from "@/requests/logistics/directory/riders";

export default {
  name: "RiderDelete",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    rider: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteRider() {
      this.deleting = true;
      const payload = {
        rider_id: this.rider.id,
      };
      riders
        .delete(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.close();
          } else {
            this.deleting = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    close() {
      this.setShow = false;
      this.deleting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
